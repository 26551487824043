import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import MailchimpSignUp from './mailchimp'
import MeshBackground from "../../content/assets/gradient-mesh.png"

const Container = styled.div`
  background-image: url(${MeshBackground});
  background-position: center;
  height: 100vh;
  max-height: 100vh;

  @media screen and (max-width: 900px) { 
    padding-bottom: 36px;
    min-height: 600px;
  }
`
const SubpageContainer = styled.div`
  background-color: black;
  background-position: center;
  height: 55vh;
  max-height: 55vh;
  min-height: 480px;

  * { 
    color: white !important;
  }
`

const HeroContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60%;

  @media screen and (max-width: 900px) {
    height: 70%;
  }
`

const PageTitle = styled.h1`
  font-size: 64px;
  font-family: "Raleway", sans-serif;
  text-align: center;
  color: white;
  margin: 12px;

  @media screen and (max-width: 900px) {
    font-size: 48px;
  }
`

const PageSubtitle = styled.p`
  color: white;
  font-size: 36px;
  font-family: "Roboto", sans-serif;
  font-weight: 200;
  text-align: center;
  margin: 0;

  @media screen and (max-width: 900px) {
    font-size: 24px;
    max-width: 80%;
    margin: 12px auto;
  }
`

const NavBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
  padding: 60px 0 0 0;
  margin: auto;

  @media screen and (max-width: 900px) {
    padding: 24px 0 0 0;
    width: 88%;
  }
`

const NavLogo = styled.img`
  height: 120px;
  width: 120px;

  @media screen and (max-width: 900px) {
    margin-right: 36px;
    height: 72px;
    width: 72px;
  }
`

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  width: 500px;

  @media screen and (max-width: 900px) {
    display: none;
  }
`

const NavItem = styled(Link)`
  flex: 1;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: bold;
  font-family: "Raleway", sans-serif;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  opacity: 0.6;

  :hover {
    opacity: 1;
    text-decoration: underline;
  }

  @media screen and (max-width: 900px) {
    font-size: 18px;
  }
`

class Hero extends Component {
  renderHomepage() {
    return (
      <Container>
        <NavBar>
          <Link to="/">
            <NavLogo src={this.props.logo} alt="test"></NavLogo>
          </Link>
          <NavLinks>
            <NavItem
              to="/about"
              activeStyle={{
                fontWeight: "bold",
                textDecoration: "underline",
                opacity: 1,
              }}
            >
              About
            </NavItem>
            <NavItem
              to="/blog"
              activeStyle={{
                fontWeight: "bold",
                textDecoration: "underline",
                opacity: 1,
              }}
            >
              Blog
            </NavItem>
            <NavItem
              to="/faq"
              activeStyle={{
                fontWeight: "bold",
                textDecoration: "underline",
                opacity: 1,
              }}
            >
              FAQ
            </NavItem>
          </NavLinks>
        </NavBar>
        <HeroContent>
          <PageTitle>{this.props.title}</PageTitle>
          <PageSubtitle>{this.props.subtitle}</PageSubtitle>
        </HeroContent>
        <MailchimpSignUp/>
      </Container>
    )
  }

  renderSubpage() {
    return (
      <SubpageContainer>
        <NavBar>
          <Link to="/">
            <NavLogo src={this.props.logo} alt="test"></NavLogo>
          </Link>
          <NavLinks>
            <NavItem
              to="/about"
              activeStyle={{
                fontWeight: "bold",
                textDecoration: "underline",
                opacity: 1,
              }}
            >
              About
            </NavItem>
            <NavItem
              to="/blog"
              activeStyle={{
                fontWeight: "bold",
                textDecoration: "underline",
                opacity: 1,
              }}
            >
              Blog
            </NavItem>
            <NavItem
              to="/faq"
              activeStyle={{
                fontWeight: "bold",
                textDecoration: "underline",
                opacity: 1,
              }}
            >
              FAQ
            </NavItem>
          </NavLinks>
        </NavBar>
        <HeroContent>
          <PageTitle>{this.props.title}</PageTitle>
          <PageSubtitle>{this.props.subtitle}</PageSubtitle>
        </HeroContent>
      </SubpageContainer>
    )
  }

  render() {
    console.log(this.props);
    if (this.props.location.pathname === "/" || this.props.location.pathname === "/blog") {
      return this.renderHomepage()
    } else {
      return this.renderSubpage()
    }
  }
}

export default Hero
