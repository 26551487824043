import React, { Component } from "react"

class Mailchimp extends Component {
  createSignup() {
    return {
      __html: `
      <style>
        .email-sign-up { 
          height: 32px; 
          font-size: 18px; 
          width: 336px; 
          text-align: center; 
          border-radius: 12px; 
          box-shadow: none; 
          border: none; 
          box-shadow: none; 
          outline: none;"
        }

        .sign-up-text { 
          color: white;
          margin-right: 12px;
          padding-top: 8px;
          font-size: 22px;
          font-weight: bold;
          font-family: "Roboto", sans-serif;
        }

        .sign-up-btn { 
          background-color: black;
          border: none;
          border-radius: 24px; 
          color: white; 
          padding: 12px 24px; 
          text-align: center; 
          text-decoration: none; 
          display: inline-block; 
          font-size: 16px;
        }

        .top-row-container { 
          display: flex; 
          justify-content: flex-end;
          margin-bottom: 12px; 
          margin-right: 12%;
        }

        .bottom-row-container { 
          display: flex; 
          justify-content: flex-end;
          margin-bottom: 12px; 
          margin-right: 12%;
        }

        @media screen and (max-width: 900px) { 
          .email-sign-up { 
            width: calc(100% - 12%);
            max-width: calc(100% - 12%);
            font-size: 16px;
          }

          .top-row-container { 
            margin-right: 8%;
          }

          .bottom-row-container { 
            margin-right: 10%;
          }

          .sign-up-text { 
            padding-top: 10px;
            margin-right: 22px;
            font-size: 18px;
          }

          .sign-up-btn { 
            -webkit-appearance: none;
            border-radius: 14px;
            padding: 10px 18px;
          }
        }

        @media screen and (max-width: 360px) { 
          .top-row-container { 
            margin-right: 24px;
          }

          .bottom-row-container { 
            margin-right: 24px;
          }

          .sign-up-text { 
            margin-right: 6px;
          }

          .sign-up-btn { 
            padding: 8px 12px;
          }
        }
      </style>
      <div id="mc_embed_signup">
        <form action="https://social.us10.list-manage.com/subscribe/post?u=2756654f5cd94d5dde956d8a5&amp;id=24b22fe731" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
        <div class="mc-field-group top-row-container">
          <input type="email" value="" name="EMAIL" placeholder="email address" class="required email email-sign-up" id="mce-EMAIL">
        </div>
          <div id="mce-responses" class="clear" >
            <div class="response" id="mce-error-response" style="display:none"></div>
            <div class="response" id="mce-success-response" style="display:none"></div>
          </div>
            <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_2756654f5cd94d5dde956d8a5_24b22fe731" tabindex="-1" value=""></div>
            <div class="clear bottom-row-container">
              <label for="mce-EMAIL" class="sign-up-text">Get the latest updates</label>
              <input type="submit" value="Sign up" name="subscribe" id="mc-embedded-subscribe" class="button sign-up-btn">
            </div>
            </div>
        </form>
      </div> 
    `,
    }
  }

  render() {
    return <div dangerouslySetInnerHTML={this.createSignup()} />
  }
}

export default Mailchimp
