import React, { Component } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import MeshBackground from "../../content/assets/gradient-mesh.png"
import TwitterIcon from "../../content/assets/social/social-icon-twitter.svg"
import GitHubIcon from "../../content/assets/social/social-icon-github.svg"
import EmailIcon from "../../content/assets/social/social-icon-email.svg"

const Container = styled.div`
  background-image: url(${MeshBackground});
  background-position: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 6% 10%;
  max-height: 44vh;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    max-height: none;
  }
`

const Links = styled.div`
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    width: 80%;
  }
`

const LinkCol = styled.div`
  display: flex;
  flex-direction: column;
`

const LinkHeader = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 36px;
  font-family: "Raleway", sans-serif;
  padding-bottom: 18px;
  opacity: 0.6;

  :hover {
    opacity: 1;
    text-decoration: underline;
  }

  @media screen and (max-width: 1200px) {
    opacity: 0.8;
    font-size: 24px;
  }

  @media screen and (max-width: 1000px) {
    padding-top: 24px;
  }
`

const LinkItem = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-weight: 200;
  padding-bottom: 12px;
  opacity: 0.6;

  :hover {
    opacity: 1;
    text-decoration: underline;
  }

  @media screen and (max-width: 1200px) {
    opacity: 0.8;
    font-size: 18px;
  }
`

const Socials = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 20%;
  width: 24%;
  min-width: 200px;

  @media screen and (max-width: 1200px) {
    padding-left: 10%;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
  }

  @media screen and (max-width: 1000px) {
    padding: 48px 0 24px 0;
    margin: auto;
    width: 280px;
    min-width: 260px;
    max-width: 280px;
  }
`

const Twitter = styled.a`
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }

  img {
    height: 100%;
  }

  @media screen and (max-width: 1200px) {
    opacity: 0.8;
    height: 48px;
    width: 48px;
  }
`

const Github = styled.a`
  height: 72px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;

  :hover {
    opacity: 1;
  }

  img {
    height: 100%;
    fill: white;
  }

  @media screen and (max-width: 1200px) {
    opacity: 0.8;
    height: 52px;
    width: 52px;
  }
`

const Email = styled.a`
  height: 72px;
  width: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
  padding-right: 6px;

  :hover {
    opacity: 1;
  }

  img {
    height: 100%;
    fill: white;
  }

  @media screen and (max-width: 1200px) {
    opacity: 0.8;
    height: 52px;
    width: 52px;
  }
`

class Footer extends Component {
  render() {
    return (
      <Container>
        <Links>
          <LinkCol>
            <LinkHeader
              to="/about"
              activeStyle={{
                fontWeight: "bold",
                textDecoration: "underline",
                opacity: 1,
              }}
            >
              About
            </LinkHeader>
            <LinkItem to="/about#contact">Contact</LinkItem>
            <LinkItem to="/about#team">Team</LinkItem>
            <LinkItem to="/about#partners">Partners</LinkItem>
          </LinkCol>
          <LinkCol>
            <LinkHeader
              to="/blog"
              activeStyle={{
                fontWeight: "bold",
                textDecoration: "underline",
                opacity: 1,
              }}
            >
              Blog
            </LinkHeader>
            <LinkItem to="/introducing-clink/">Announcement</LinkItem>
            <LinkItem to="/what-is-the-metaverse/">What's the metaverse?</LinkItem>
          </LinkCol>
          <LinkCol>
            <LinkHeader
              to="/faq"
              activeStyle={{
                fontWeight: "bold",
                textDecoration: "underline",
                opacity: 1,
              }}
            >
              FAQ
            </LinkHeader>
            <LinkItem to="/faq#how-to">How does it work?</LinkItem>
            <LinkItem to="/faq#token-use">What are Clink tokens?</LinkItem>
            <LinkItem to="/faq#build-on-the-platform">
              Build with Clink
            </LinkItem>
          </LinkCol>
        </Links>
        <Socials id="contact">
          <Email
            href="mailto:info@clink.social"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={EmailIcon} alt="Email" />
          </Email>
          <Twitter
            href="https://twitter.com/clink_social"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={TwitterIcon} alt="Twitter" />
          </Twitter>
          <Github
            href="https://github.com/clink-social"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={GitHubIcon} alt="GitHub" />
          </Github>
        </Socials>
      </Container>
    )
  }
}

export default Footer
