import React, { Component } from "react"
import Hero from "./hero"
import Footer from "./footer"

import ClinkLogoWhite from "../../content/assets/clink-logo-white.png"

import { createGlobalStyle } from 'styled-components'
import ReactGA from 'react-ga';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
  }
`

class Layout extends Component  {
  initGoogleAnalytics() { 
    ReactGA.initialize('UA-174550439-1');
    ReactGA.pageview(this.props.location.pathname + this.props.location.search);
  }

  render() { 
    this.initGoogleAnalytics();

    let header = (
      <Hero
        location={this.props.location}
        title={this.props.heroTitle}
        subtitle={this.props.heroSubtitle}
        logo={ClinkLogoWhite}
        theme="gradient-mesh"
      />
    )
  
    return (
      <div style={{ margin: 0, padding: 0 }}>
        <link
          href="https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,900;1,100;1,200;1,300;1,400;1,500;1,700;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        ></link>
        <GlobalStyles/>
        <header>{header}</header>
        <main>{this.props.children}</main>
        <Footer/>
      </div>
    )
  }
}

export default Layout
